import { Avatar, Box, Typography } from '@mui/material'
import { MButton } from 'components'
import { ROUTES_PATH } from 'config'
import { color } from 'config/color'
import { type IDashboardStats } from 'containers/Dashboard/Dashboard'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { formatValue } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

import { ReactComponent as HighestUsageIcon } from 'assets/icons/HighestUsageIcon.svg'
import { ReactComponent as TotalUsageIcon } from 'assets/icons/TotalUsageIcon.svg'
import { useAuth } from 'context'

interface IProps {
  dashboardStats: IDashboardStats
}
export const NumberStats = ({ dashboardStats }: IProps): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  const { isAccountDisabled } = useAuth()
  const navigate = useNavigate()
  return (
    <Box display="flex" flexDirection="column" bgcolor={color.white} p={3} gap={2}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box>
          <Typography color={color.grey[900]} fontWeight={600} fontSize={18}>This Month Usage</Typography>
          <Typography color={color.grey[700]} fontSize={12}>{dayjs().format('MMMM YYYY')}</Typography>
        </Box>
        <MButton variant="outlined" rounded="true" texttransform="none" onClick={() => { navigate(ROUTES_PATH.USAGE) }} disabled={isAccountDisabled}>View All</MButton>
      </Box>

      <Box display="flex" alignItems="center" gap={2} border={`solid 1px ${color.background[300]}`} borderRadius={1} p={1} mb={1}>

        <Avatar sx={{ bgcolor: color.grey[100], p: 2 }}>
          <TotalUsageIcon fontSize='large' width={50} height={50} fill={color.primary[700]} />
        </Avatar>
        <Box>
          <Typography color={color.grey[900]} fontWeight={600} fontSize={24}>{formatValue(`${dashboardStats.usageThisMonth}`, false, false)} m³</Typography>
          <Typography fontSize={isMobile ? 10 : 12} color={color.grey[700]}>TOTAL USAGE THIS MONTH</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={2} border={`solid 1px ${color.background[300]}`} borderRadius={1} p={1}>

        <Avatar sx={{ bgcolor: color.grey[100], p: 2 }}>
          <HighestUsageIcon fontSize='large' width={50} height={50} fill={color.primary[700]} />
        </Avatar>
        <Box>
          <Typography color={color.grey[900]} fontWeight={600} fontSize={24}>{formatValue(`${dashboardStats.highestThisYear}`, false, false)} m³</Typography>
          <Typography fontSize={isMobile ? 10 : 12} color={color.grey[700]}>HIGHEST THIS YEAR</Typography>
        </Box>
      </Box>

    </Box>

  )
}
