import { Email, Person, Phone } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { GenericDialog, MButton } from 'components'
import { color } from 'config/color'
import { useAuth } from 'context'
import { type IUnlinkGuest } from 'services'

interface IRemoveUserDialog {
  open: boolean
  isLoading: boolean
  guestUser: {
    personName: string
    personId: string
    email: string
    phoneNumber: string
    guestAcctId: string
  }
  service: {
    accountNumber: string
    serviceAddress: string
  } | null
  onClose: () => void
  unlinkPerson: (data: IUnlinkGuest) => void
  isUnlink: boolean
}

enum unlinkFrom {
  ACT = 'ACT',
  PER = 'PER'
}

export const RemoveUserDialog = ({
  guestUser,
  service,
  open,
  isLoading,
  onClose,
  unlinkPerson,
  isUnlink
}: IRemoveUserDialog): JSX.Element => {
  const { account } = useAuth()

  return (
    <GenericDialog open={open} onClose={onClose} iconType="error">
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" flexDirection="column">
            <Typography color={color.grey[900]} fontWeight={500} fontSize={20} align='center'>
              {isUnlink ? 'Unlinking user from an account' : 'Remove a user'}
            </Typography>

            {isUnlink
              ? (
                <Typography color={color.grey[700]} fontWeight={400} fontSize={16} align='center'>
                  You’re about to unlink <span style={{ fontWeight: 'bold', color: color.grey[900] }}>{guestUser.personName}</span> from this account. The user will no longer be able to view it in their portal. However, you can relink them at any time in the future if needed.
                </Typography>
              )
              : (
                <Typography color={color.grey[700]} fontWeight={400} fontSize={16} align='center'>
                  You’re about to delete the following guest user. This will remove the user from all linked accounts. Please note that this action is permanent and cannot be undone.
                </Typography>
              )}

          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          {isUnlink
            ? (
              <RemoveServiceDialogCard
                accountId={service?.accountNumber ?? ''}
                serviceAddress={service?.serviceAddress ?? ''}
              />
            )
            : (
              <RemoveUserDialogCard
                accountId={guestUser.guestAcctId}
                personName={guestUser.personName}
                phoneNumber={guestUser.phoneNumber}
                email={guestUser.email} />
            )
          }
        </Box>

        <Box display="flex" flexDirection="column" px={3} py={1} gap={1}>
          <MButton
            loading={isLoading}
            type="submit"
            variant="contained"
            rounded="true"
            texttransform="none"
            onClick={() => {
              const accountId = isUnlink ? service?.accountNumber : account?.myAccount.accountId ?? ''
              if (accountId === null) return
              const body: IUnlinkGuest = {
                personId: guestUser.personId,
                accountId: accountId ?? '',
                unlinkFrom: isUnlink ? unlinkFrom.ACT : unlinkFrom.PER,
                guestAccountId: guestUser.guestAcctId
              }
              if (isUnlink) {
                body.accountList = [{
                  accountId: ''
                }]
              }
              unlinkPerson(body)
            }}
          >
            Unlink User
          </MButton>

          <MButton
            variant="text"
            type="button"
            rounded="true"
            texttransform="none"
            onClick={onClose}
          >
            No, go back to My Account
          </MButton>
        </Box>
      </Box>
    </GenericDialog>
  )
}

const RemoveUserDialogCard = ({ accountId, email, personName, phoneNumber }: { accountId: string, email: string, personName: string, phoneNumber: string }): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      bgcolor={color.background[50]}
      p={2}
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <Person
          sx={{
            color: color.background[700],
            backgroundColor: color.background[200],
            fontSize: 38,
            borderRadius: '50%',
            padding: 1,
            ml: 1,
            mr: 2
          }}
        />

        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography color={color.grey[900]} fontSize={18}>
            {personName}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
          >
            <Email sx={{ color: color.background[600], fontSize: 12 }} />

            <Typography color={color.grey[700]} fontSize={12}>
              {email}
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
          >
            <Person
              sx={{
                color: color.background[600],
                borderRadius: '50%',
                fontSize: 14
              }}
            />
            <Typography color={color.grey[700]} fontSize={12}>
              {accountId}
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
          >
            <Phone sx={{ color: color.background[600], fontSize: 12 }} />

            <Typography color={color.grey[700]} fontSize={12}>
              {phoneNumber}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const RemoveServiceDialogCard = ({ accountId, serviceAddress }: { accountId: string, serviceAddress: string }): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      bgcolor={color.background[50]}
      p={2}
    >
      <Box display="flex" flexDirection="column" gap={0.5} pt={1}>
        <Typography color={color.grey[700]} fontSize={10} fontWeight={400}>
          ACCOUNT NUMBER
        </Typography>
        <Typography color={color.grey[900]} fontSize={14} fontWeight={400}>
          {accountId}
        </Typography>

        <Typography color={color.grey[700]} fontSize={10} fontWeight={400}>
          SERVICE ADDRESS
        </Typography>
        <Typography color={color.grey[900]} fontSize={14} fontWeight={400}>
          {serviceAddress}
        </Typography>
      </Box>
    </Box>
  )
}
