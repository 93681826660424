import Box from '@mui/material/Box'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { GenericDialog, MButton } from 'components'
import { Typography } from '@mui/material'

export const GuestConfirmationDialog = ({
    open,
    onClose,
    onConfirm
}: any): JSX.Element => {
    const isMobile = useMobileBreakpoints()
    const handleOnSave = async (): Promise<void> => {
        await onClose()
        await onConfirm()
    }

    return (
        <Box>
            <GenericDialog
                open={open}
                onClose={onClose}
                title='You are switching to a guest account'
            >
                <Box>
                    <Typography
                        color='#5D6365'
                        fontSize={isMobile ? 14 : 16}
                        textAlign={isMobile ? 'justify' : 'center'}
                        mt={3}
                    >
                        When viewing the account, please note that some functionality my be limited.
                    </Typography>

                    <Typography
                        color='#5D6365'
                        fontSize={isMobile ? 14 : 16}
                        textAlign={isMobile ? 'justify' : 'center'}
                        mt={3}
                    >
                        After viewing the guest account, if you need to switch back, refresh your page.
                    </Typography>

                    <Typography
                        color='#5D6365'
                        fontSize={isMobile ? 14 : 16}
                        textAlign={isMobile ? 'justify' : 'center'}
                        mb={3}
                        mt={3}
                    >
                        Do you want to proceed?
                    </Typography>

                    <Box display='flex' flexDirection='row' gap={1}>
                        <MButton
                            variant='contained'
                            size='large'
                            rounded='true'
                            type='button'
                            fullWidth
                            onClick={handleOnSave}
                            texttransform='none'
                        >
                            Yes
                        </MButton>

                        <MButton
                            variant='outlined'
                            type='button'
                            size='large'
                            rounded='true'
                            fullWidth
                            onClick={onClose}
                            texttransform='none'
                        >
                            No
                        </MButton>
                    </Box>
                </Box>
            </GenericDialog>
        </Box>
    )
}
