/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import PaymentsIcon from '@mui/icons-material/Payments'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import {
  Box,
  Grid,
  Typography,
  styled
} from '@mui/material'
import { ReactComponent as RuggedBottom } from 'assets/icons/RuggedBottom.svg'
import { MButton, ProgressLoaders, Text } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { CONTACT_EMAIL, CONTACT_PHONE, MESSAGES, PAYMENT_LINK, USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { formatValue, useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { SecondaryUser } from './component/SecondaryUser'

export interface IUserValue {
  name?: string
  officeNumber?: string
  mobileNumber?: string
  mailingAddress?: string
  email?: string
  balance?: string
  alteredEmail?: string
  serviceAddress?: string[]
}

export const Account: React.FC = () => {
  const { account, billing, isAdmin, userEmail, currentAccount, getAccount, getBilling, isGuestAccount, isLoggedInAsGuest } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [userValue, setUserValue] = useState<IUserValue>()
  const [defaultAccountId, setDefaultAccountId] = useState<any>(null)
  const isMobile = useMobileBreakpoints()
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const secondaryPerList = account?.secondaryPerList
  const shouldShowGuest = isLoggedInAsGuest || isGuestAccount

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  useEffect(() => {
    if (account) {
      const serviceAddresses = account?.myAccount?.serviceAddresses?.map(item => item.serviceAddress) ?? []
      setUserValue({
        name: account?.myAccount?.personName,
        officeNumber: account?.myAccount?.alternateNumber,
        mobileNumber: account?.myAccount?.mobileNumber,
        mailingAddress: [
          account?.myAccount?.mailingAddress?.addressLine1,
          account?.myAccount?.mailingAddress?.city,
          account?.myAccount?.mailingAddress?.state,
          account?.myAccount?.mailingAddress?.country,
          account?.myAccount?.mailingAddress?.postal
        ]
          .filter(Boolean)
          .join(', '),
        email: account?.myAccount?.email,
        balance:
          account?.myAccount?.accountBalance &&
          formatValue(account?.myAccount.accountBalance),
        alteredEmail: 'ND',
        serviceAddress: serviceAddresses
      })
    }
    if (!billing) {
      void getBilling({
        AccessToken: authToken,
        accountId: account?.myAccount.accountId,
        startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        admin: isAdmin,
        email: userEmail
      })
    }
  }, [account, billing])

  useEffect(() => {
    if (account && !defaultAccountId) {
      setDefaultAccountId(account?.myAccount.defaultAccountId)
    }
  }, [account, defaultAccountId])


  // const handleCheckBox = async (item: any): Promise<void> => {
  //   try {
  //     const body: any = {
  //       AccessToken: authToken,
  //       accountId: account?.myAccount.accountId,
  //       admin: isAdmin,
  //       email: userEmail
  //     }
  //     body.updates = [
  //       {
  //         object: 'DEFAULTACCOUNT',
  //         value: item.accountId
  //       }
  //     ]
  //     await APIs.updateUser(body)
  //     enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_SUCCESS, {
  //       variant: 'success',
  //       autoHideDuration: 3000
  //     })
  //     setDefaultAccountId(item.accountId)
  //     handleCurrentAccount({ accountId: item.accountId ?? '', premiseId: item?.premiseId ?? '' })
  //     void getAccount({
  //       AccessToken: authToken,
  //       accountId: item.accountId
  //     })
  //   } catch (e) {
  //     enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_ERROR, {
  //       variant: 'error',
  //       autoHideDuration: 3000
  //     })
  //   }
  // }


  // const handleEdit = async (): Promise<void> => {
  //   // setIsLoading(true)

  //   if (isEditing && mailingAddress) {
  //     try {
  //       const response = await updateMailAddress({
  //         AccessToken: authToken,
  //         addressLine1: mailingAddress.addressLine1,
  //         addressLine2: mailingAddress.addressLine2,
  //         postal: mailingAddress.postal,
  //         city: mailingAddress.city,
  //         state: mailingAddress.state
  //       })
  //       if (response && response?.status === 'success') {
  //         enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_SUCCESS, {
  //           variant: 'success',
  //           autoHideDuration: 3000
  //         })
  //         setUserValue({
  //           ...userValue,
  //           mailingAddress: `${mailingAddress.addressLine1}, ${mailingAddress.city}, ${mailingAddress.state}, - ${mailingAddress.postal}`
  //         })
  //       }
  //     } catch (e: any) {
  //       if (e && e?.response?.data?.code === 'UPDATE_LIMIT_EXCEEDED') {
  //         enqueueSnackbar(MESSAGES.UPDATE_LIMIT_EXCEEDED, {
  //           variant: 'error',
  //           autoHideDuration: 3000
  //         })
  //       } else {
  //         enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_ERROR, {
  //           variant: 'error',
  //           autoHideDuration: 3000
  //         })
  //       }
  //     }
  //   }

  //   if (isEditing && portalPhone) {
  //     try {
  //       const response = await APIs.updateUser({
  //         AccessToken: authToken,
  //         accountId: account?.myAccount.accountId ?? '',
  //         admin: isAdmin,
  //         email: userEmail,
  //         updates: [
  //           {
  //             object: 'PORTALPHONE',
  //             value: portalPhone
  //           }
  //         ]
  //       })
  //       if (response.status === 1) {
  //         enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_SUCCESS, {
  //           variant: 'success',
  //           autoHideDuration: 3000
  //         })
  //         setUserValue({
  //           ...userValue,
  //           mobileNumber: portalPhone
  //         })
  //       }
  //     } catch (e) {
  //       enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_ERROR, {
  //         variant: 'error',
  //         autoHideDuration: 3000
  //       })
  //     }
  //   }

  //   // setIsLoading(false)
  //   setIsEditing(!isEditing)
  // }

  // const handleMailingAddressChange = (mailingAddress: IMailingAddress): void => {
  //   setMailingAddress(mailingAddress)
  // }

  // const handlePortalPhoneChange = (portalPhone: string): void => {
  //   setPortalPhone(portalPhone)
  // }

  const handleDownloadBill = (): void => {
    if (!isNil(account?.myAccount?.billDisplayURL)) {
      window.open(account?.myAccount?.billDisplayURL, '_blank', 'noopener,noreferrer')
    } else {
      enqueueSnackbar(MESSAGES.BILL_PDF_ERROR, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }

  return (
    <>
      {isMobile && <QuickLinks />}
      {account !== null
        ? <Grid container spacing={2} mt={0} flexDirection={isMobile ? 'column' : 'row'}>
          <Grid item sm={10} md={8} maxWidth="100% !important">
            <Wrapper gap={2}>
              <BoxWrapper>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                  <Typography color={color.grey[900]} fontSize={isMobile ? 18 : 24} fontWeight="600">
                    General Information
                  </Typography>
                  {/* {!isMobile && <MButton
                    rounded="true"
                    variant={isEditing ? 'contained' : 'outlined'}
                    texttransform="none"
                    onClick={handleEdit}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="inherit" size={20} />
                      )
                    }
                  >
                    {isEditing ? 'Save Changes' : 'Edit Information'}
                  </MButton>} */}

                </Box>

                {userValue &&
                  <GeneralInformation userValue={userValue} />
                }
              </BoxWrapper>

              {!shouldShowGuest && (
                <>
                  <BoxWrapper>
                    <Typography color={color.grey[900]} fontSize={isMobile ? 18 : 24} fontWeight={600}>
                      Service Address
                    </Typography>
                    <Box my={2}>
                      <Typography color={color.grey[800]} fontSize={16} fontWeight={400}>
                        Select your primary account. This will show by default when you log into the portal. If there are additional service addresses that are not listed below please contact:
                      </Typography>
                      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={1} my={2}>
                        <Box flex={1} textAlign="center" bgcolor={color.background[50]} py={1}>
                          <Typography fontSize={16} color={color.grey[900]}>Call Center/Operator</Typography>
                          <Typography fontSize={16} color={color.primary[700]}>{CONTACT_PHONE}</Typography>
                        </Box>
                        <Box flex={1} textAlign="center" bgcolor={color.background[50]} py={1}>
                          <Typography fontSize={16} color={color.grey[900]}>Email</Typography>
                          <Typography fontSize={16} color={color.primary[700]}>{CONTACT_EMAIL}</Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center" gap={0.5} p={1} bgcolor={color.background[100]}>
                      <WarningRoundedIcon fontSize="small" color="warning" />
                      <Typography fontSize={16} fontWeight={600}>Service Account Information</Typography>
                    </Box>
                    <Box p={1} bgcolor={color.background[50]}>
                      <Typography fontSize={12} color={color.grey[700]}>WEB Aruba provides Customers with all account information and meter readings. For this reason, all WEB Aruba account holders must also have a portal account in order to view their services and data.</Typography>
                    </Box>

                  </BoxWrapper>

                  <SecondaryUser
                    secondaryPerList={secondaryPerList ?? []}
                  />
                </>
              )}
            </Wrapper>
          </Grid>
          <Grid item sm={12} md={4}>
            <BoxWrapper>
              <Box display="flex" alignItems="center" mb={3}>
                <PaymentsIcon fontSize='medium' sx={{ color: color.secondary[500] }} />
                <Typography ml={1} color={color.grey[900]} fontSize={isMobile ? 18 : 24} fontWeight="600">
                  Current Bill Detail
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={3}
              >
                <Text color={color.black} fontSize="18px">
                  Total Due
                </Text>
                <Box
                  p="12px 16px"
                  sx={{ background: color.background[100], borderRadius: '4px' }}
                >
                  <Text color={color.grey[900]} fontSize="24px" fontWeight="600">
                    {userValue?.balance}
                  </Text>
                </Box>
              </Box>

              <MButton
                fullWidth
                rounded="true"
                variant="contained"
                texttransform='none'
                size="large"
                onClick={handleDownloadBill}
              >
                View Bill
              </MButton>

              <MButton
                sx={{ mt: 1 }}
                fullWidth
                rounded="true"
                variant="outlined"
                texttransform='none'
                size="large"
                onClick={() => window.open(PAYMENT_LINK, '_blank')}
              >
                Pay Bill
              </MButton>

            </BoxWrapper>
            <RuggedBottom width="100%" />
          </Grid>
        </Grid >
        : <Box mt={3}><ProgressLoaders height="100%" /></Box>}
    </>

  )
}

const GeneralInformation = ({ userValue }: { userValue: IUserValue }): JSX.Element => {
  return (
    <Grid borderRadius="4px" container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color={color.grey[800]} fontSize="12px" margin="0 0 2px">
            NAME
          </Text>
          <Text color={color.grey[900]} fontSize="16px">
            {userValue?.name}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color={color.grey[800]} fontSize="12px" margin="0 0 2px">
            HOME / OFFICE NUMBER
          </Text>
          <Text color={color.grey[900]} fontSize="16px">
            {userValue && (userValue.mobileNumber ?? userValue.officeNumber)}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color={color.grey[800]} fontSize="12px" margin="0 0 2px">
            MAILING ADDRESS
          </Text>
          <Text color={color.grey[900]} fontSize="16px">
            {userValue?.mailingAddress}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color={color.grey[800]} fontSize="12px" margin="0 0 2px">
            EMAIL ID
          </Text>
          <Text color={color.grey[900]} fontSize="16px">
            {userValue?.email}
          </Text>
        </Box>
      </Grid>
    </Grid>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const BoxWrapper = styled(Box)({
  background: color.white,
  borderRadius: '4px',
  padding: '24px'
})
