import { Box, Divider, Typography } from '@mui/material'
import { Person, Email, Phone, Link, Delete } from '@mui/icons-material'
import { color } from 'config/color'
import { useMobileBreakpoints } from 'utils'
import { MButton } from 'components'
import { type Dispatch, type SetStateAction } from 'react'
import { type ISecondaryPerList } from 'utils/hooks/types'
import { useAuth } from 'context'
// import { useAuth } from 'context'

interface IGuestUserCard {
  setIsRemoveUserDialogOpen: Dispatch<SetStateAction<boolean>>
  setGuestUser: Dispatch<SetStateAction<{ personName: string, personId: string, accountId: string, phoneNumber: string, email: string, guestAcctId: string }>>
  secondaryPerson: ISecondaryPerList
}

export const GuestUserCard = ({
  setIsRemoveUserDialogOpen,
  setGuestUser,
  secondaryPerson
}: IGuestUserCard): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  const { account } = useAuth()


  if (secondaryPerson === undefined || secondaryPerson === null) {
    return <></>
  }

  return (
    <Box
      bgcolor={color.grey[50]}
      height={isMobile ? 'auto' : 163}
      border={`1px solid ${color.background[300]}`}
      width={isMobile ? '100%' : '50%'}
      display="flex"
      flexDirection="column"
      justifyContent={isMobile ? 'center' : 'space-between'}
    >
      <Box
        p={1.5}
        display="flex"
        flexDirection="column"
        gap={3}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="row" gap={1} justifyContent={isMobile ? 'left' : 'space-between'}>
            <Person
              sx={{
                color: color.background[700],
                backgroundColor: color.background[200],
                fontSize: 38,
                borderRadius: '50%',
                padding: 1
              }}
            />

            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography color={color.grey[900]} fontSize={18}>
                {secondaryPerson.personName}
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                <Email sx={{ color: color.background[600], fontSize: 12 }} />

                <Typography color={color.grey[700]} fontSize={12}>
                  {secondaryPerson.email}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                <Person
                  sx={{
                    color: color.background[600],
                    borderRadius: '50%',
                    fontSize: 14
                  }}
                />
                <Typography color={color.grey[700]} fontSize={12}>
                  {secondaryPerson.guestAcctId}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                <Phone sx={{ color: color.background[600], fontSize: 12 }} />

                <Typography color={color.grey[700]} fontSize={12}>
                  {secondaryPerson.phoneNumber}
                </Typography>
              </Box>

              {isMobile && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                >
                  <Link
                    sx={{
                      color: color.background[600],
                      borderRadius: '50%',
                      fontSize: 14
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          {/* {!isMobile && (
            <Box
              display="flex"
              flexDirection="row"
              gap={1}
              p={0.5}
              bgcolor={color.white}
              borderRadius="20px"
              width="100px"
            >
              <Link
                sx={{
                  color: color.white,
                  backgroundColor: color.success[500],
                  borderRadius: '50%',
                  padding: 0.5,
                  fontSize: 12
                }}
              />
              <Typography color={color.grey[700]} fontSize={12}>
                3 Accounts
              </Typography>
            </Box>
          )} */}
        </Box>

        {/* <MButton
          variant="outlined"
          type="button"
          rounded="true"
          texttransform="none"
          fullWidth
        >
          Link to all the accounts
        </MButton> */}
      </Box>

      <Box display="flex" flexDirection="column" alignContent="center">
        <Divider sx={{ color: color.background[400] }} />

        <MButton
          component="label"
          type="button"
          size="medium"
          rounded="true"
          texttransform="none"
          startIcon={<Delete sx={{ color: color.background[600] }} />}
          onClick={() => {
            setIsRemoveUserDialogOpen(true)
            setGuestUser({
              personId: secondaryPerson.personId,
              personName: secondaryPerson.personName,
              email: secondaryPerson.email,
              phoneNumber: secondaryPerson.phoneNumber,
              guestAcctId: secondaryPerson.guestAcctId,
              accountId: account?.myAccount?.accountId ?? ''
            })
          }
          }>
          <Typography color={color.black} fontSize={12}>
            Remove User
          </Typography>
        </MButton>
      </Box>
    </Box >
  )
}
