import styled from '@emotion/styled'
import { Add, Circle } from '@mui/icons-material'
import { Box, Divider, Link, Radio, Typography } from '@mui/material'
import { FormLabel } from 'components'
import { GuestConfirmationDialog } from 'components/ui/Dialog/GuestConfirmationDialog'
import { MESSAGES, USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { APIs, type IServiceAddress } from 'services'
import { useLocalStorage, useMobileBreakpoints } from 'utils'
import { type ISecondaryPerList, type ServiceAddress } from 'utils/hooks/types'

interface AccountTableProps {
  serviceAddresses: ServiceAddress[]
  setIsNewUserDialogOpen: (open: boolean) => void
  setSelectedParentAccount: (account: string) => void
  setIsLinkDialogOpen: (open: boolean) => void
  setSelectedServiceAccount: Dispatch<SetStateAction<{ accountNumber: string, serviceAddress: string }>>
  setIsRemoveUserDialogOpen: (open: boolean) => void
  setGuestUser: Dispatch<SetStateAction<{ personName: string, personId: string, accountId: string, phoneNumber: string, email: string, guestAcctId: string }>>
}

export const AccountTable = ({
  serviceAddresses,
  setIsNewUserDialogOpen,
  setSelectedParentAccount,
  setIsLinkDialogOpen,
  setSelectedServiceAccount,
  setIsRemoveUserDialogOpen,
  setGuestUser
}: AccountTableProps): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  const { enqueueSnackbar } = useSnackbar()
  const { account, currentAccount, getAccount, isAdmin, userEmail, handleCurrentAccount, handleIsGuestAccount } = useAuth()
  const [defaultAccountId, setDefaultAccountId] = useState<string | null>(null)
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  // const [active] = useState<number>(0)
  const [isGuestModalOpen, setIsGuestModalOpen] = useState<boolean>(false)
  const [serviceAccount, setServiceAccount] = useState<IServiceAddress | null>(null)
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const secondaryPerList = account?.secondaryPerList ?? []

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  const handleCheckBox = async (item: IServiceAddress): Promise<void> => {
    try {
      console.log(serviceAccount)

      if (item === undefined && serviceAccount == null) throw new Error('No service account selected')

      if (item?.isLink === true) {
        handleIsGuestAccount(true)
      } else {
        handleIsGuestAccount(false)
        const body: any = {
          AccessToken: authToken,
          accountId: account?.myAccount.accountId,
          admin: isAdmin,
          email: userEmail
        }
        body.updates = [
          {
            object: 'DEFAULTACCOUNT',
            value: item.accountId
          }
        ]
        await APIs.updateUser(body)
      }

      enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_SUCCESS, {
        variant: 'success',
        autoHideDuration: 3000
      })
      setDefaultAccountId(item?.accountId ?? serviceAccount?.accountId)
      handleCurrentAccount({ accountId: item?.accountId ?? '', premiseId: item?.premiseId ?? '' })
      void getAccount({
        AccessToken: authToken,
        accountId: item?.accountId ?? serviceAccount?.accountId
      })
    } catch (e) {
      console.error(e)
      enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_ERROR, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  }

  useEffect(() => {
    if ((account !== null) && (defaultAccountId === null)) {
      setDefaultAccountId(account?.myAccount?.defaultAccountId ?? null)
    }
  }, [account, defaultAccountId])

  const AddUserContainer = ({ parentAccount }: { parentAccount: string }): JSX.Element => (
    <Box
      component="button"
      sx={{ cursor: 'pointer' }}
      width={isMobile ? '100%' : '130px'}
      borderRadius="20px"
      border={`solid 1px ${color.grey[200]}`}
      bgcolor={color.white}
      pl={2}
      py={0.5}
      onClick={() => {
        setIsNewUserDialogOpen(true)
        setSelectedParentAccount(parentAccount)
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems='center'
      >
        <Typography
          color={color.grey[700]}
          fontWeight={400}
          fontSize={14}
        >
          Add User
        </Typography>
        <Add
          sx={{
            color: color.background[700],
            backgroundColor: color.grey[100],
            borderRadius: '50%',
            padding: 1,
            fontSize: 20
          }}
        />
      </Box>
    </Box>
  )

  const LinkUserContainer = ({ accountNumber, serviceAddress }: { accountNumber: string, serviceAddress: string }): JSX.Element => (
    <Box
      sx={{ cursor: 'pointer' }}
      component="button"
      mx={0.5}
      width={isMobile ? '100%' : '130px'}
      borderRadius="20px"
      border={`solid 1px ${color.grey[200]}`}
      bgcolor={color.white}
      pl={2}
      py={0.5}
      onClick={() => {
        setSelectedServiceAccount({ accountNumber, serviceAddress })
        setIsLinkDialogOpen(true)
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems='center'
      >
        <Typography
          color={color.grey[700]}
          fontWeight={400}
          fontSize={14}
        >
          Link User
        </Typography>
        <Link
          sx={{
            color: color.background[700],
            backgroundColor: color.grey[100],
            borderRadius: '50%',
            padding: 1,
            fontSize: 20
          }}
        />
      </Box>
    </Box>
  )


  const checkIfLinked = (secondaryPerson: ISecondaryPerList, accountId: string): boolean => {
    return secondaryPerson.linkedTo?.some((i) => i.acctList === accountId) && secondaryPerson.isRegisteredToPortal
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const checkLinkedAccount = (service: IServiceAddress) => {
    const { accountId, serviceAddress, isLink } = service

    if (isLink === true) return <></>

    const linkedAccounts = secondaryPerList.flatMap(x => x?.linkedTo !== undefined ? x.linkedTo.filter((x) => x.acctList === accountId) : [])
    const linkedCount = linkedAccounts.length
    const guestUsersCount = secondaryPerList?.length

    const addUserContainer = <AddUserContainer parentAccount={accountId} />
    const linkUserContainer = <LinkUserContainer accountNumber={accountId} serviceAddress={serviceAddress} />

    switch (true) {
      case linkedCount === 0 && guestUsersCount === 0:
        return (
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} sx={{ width: '100%', gap: 1 }}>
            {addUserContainer}
            {addUserContainer}
          </Box>
        )
      case linkedCount === 1 && guestUsersCount === 1:
        return addUserContainer
      case linkedCount === 1 && guestUsersCount === 2:
        return linkUserContainer
      case linkedCount === 0 && guestUsersCount === 2:
        return (
          <>
            {linkUserContainer}
            {linkUserContainer}
          </>
        )
      case linkedCount === 0 && guestUsersCount === 1:
        return (
          <>
            {linkUserContainer}
            {addUserContainer}
          </>
        )
      default:
        return null
    }
  }

  return (
    <div>
      {serviceAddresses.filter((value: any, index: any, self: any) =>
        self.findIndex((v: any) => v.accountId === value.accountId) === index && value.isLink === false).map((service: IServiceAddress, i: number) => (
        <Box border={`1px solid ${color.background[200]}`} key={i}>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            p={1}
            bgcolor={color.grey[50]}
            gap={isMobile ? 1 : 0}
          >
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Box
                display="flex"
                flexDirection='row'
                alignItems="center"
                gap={1}
              >
                <Radio
                  onChange={() => {
                    if (service.isGuestAccount === true) {
                      setIsGuestModalOpen(true)
                      setServiceAccount(service)
                    } else {
                      void handleCheckBox(service)
                    }
                  }}
                  checked={service?.accountId === defaultAccountId}
                />
                <Box>
                  <Typography
                    fontSize={10}
                    color={color.grey[700]}
                    letterSpacing={1}
                  >
                    ACCOUNT NUMBER
                  </Typography>
                  <Typography fontSize={16} color={color.grey[900]}>
                    {service.accountId}
                    {/* {service.isGuestAccount === true && '(Guest Account)'} */}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <GuestConfirmationDialog open={isGuestModalOpen} onConfirm={handleCheckBox} onClose={() => { setIsGuestModalOpen(false) }} />

            <Box display="flex" justifyContent="end" flexDirection="row">
              {
                secondaryPerList?.filter(i => i.linkedTo?.some(x => x.acctList === service.accountId))?.map((secondaryPerson, index) => (
                  <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} key={index}>
                    <Box
                      component="button"
                      display="flex"
                      mx={0.5}
                      width={isMobile ? '100%' : 'auto'}
                      borderRadius="20px"
                      border={`solid 1px ${color.grey[200]}`}
                      bgcolor={color.white}
                      pl={2}
                      gap={30}
                      py={0.5}
                      onClick={() => {
                        setSelectedServiceAccount({ accountNumber: service.accountId, serviceAddress: service.serviceAddress })
                        setGuestUser({
                          personName: secondaryPerson.personName,
                          personId: secondaryPerson.personId,
                          accountId: service.accountId,
                          phoneNumber: secondaryPerson.phoneNumber,
                          email: secondaryPerson.email,
                          guestAcctId: secondaryPerson.guestAcctId
                        })
                        setIsRemoveUserDialogOpen(true)
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                      >
                        <Box display="flex" flexDirection="column">
                          <Typography
                            color={color.grey[800]}
                            fontWeight={400}
                            fontSize={14}
                          >
                            {secondaryPerson?.personName}
                          </Typography>

                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={1}
                          >
                            <Circle
                              sx={{
                                color: checkIfLinked(secondaryPerson, service.accountId) ? color.success[600] : color.warning[500],
                                fontSize: 12
                              }}
                            />

                            <Typography
                              color={color.grey[700]}
                              mr={1.5}
                              fontWeight={400}
                              fontSize={10}
                            >
                              {checkIfLinked(secondaryPerson, service.accountId) ? 'Linked' : 'Pending'}
                            </Typography>
                          </Box>
                        </Box>

                        <Link
                          sx={{
                            color: color.background[700],
                            backgroundColor: color.grey[100],
                            borderRadius: '50%',
                            padding: 1,
                            fontSize: 20
                          }}
                        />
                      </Box>
                    </Box>

                    {/* <Box
                    component="button"
                    width={isMobile ? '100%' : '130px'}
                    borderRadius="20px"
                    border={`solid 1px ${color.grey[200]}`}
                    bgcolor={color.white}
                    pl={2}
                    py={0.5}
                    onClick={() => {
                      setIsNewUserDialogOpen(true)
                      setSelectedParentAccount(service.accountId ?? null)
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems='center'
                    >
                      <Typography
                        color={color.grey[700]}
                        fontWeight={400}
                        fontSize={14}
                      >
                        Add User
                      </Typography>
                      <Add
                        sx={{
                          color: color.background[700],
                          backgroundColor: color.grey[100],
                          borderRadius: '50%',
                          padding: 1,
                          fontSize: 20
                        }}
                      />
                    </Box>
                  </Box> */}

                  </Box>
                ))
              }

              {checkLinkedAccount(service)}
            </Box>
          </Box>

          <Divider sx={{ color: color.background[200] }} />

          {isMobile
            ? (
              <Box>
                <LinkedAccountsTableMobileView
                  accountId={service.legacyAcctNumber}
                  serviceAddress={service.serviceAddress}
                />
              </Box>
            )
            : (
              <Table>
                <TableHeader>
                  <ItemWrapper width="70%">
                    <FormLabel textcolor={color.grey[600]}>
                      PROPERTY ADDRESS (1)
                    </FormLabel>
                  </ItemWrapper>
                  <ItemWrapper width="30%">
                    <FormLabel textcolor={color.grey[600]}>
                      OLD ACCOUNT NUMBER
                    </FormLabel>
                  </ItemWrapper>
                </TableHeader>

                <Divider sx={{ color: color.background[200] }} />

                <TableContent>
                  <TableItem>
                    <ItemWrapper width="70%">
                      <Typography color={color.grey[700]} fontSize={14}>
                        {service.serviceAddress}
                      </Typography>
                    </ItemWrapper>
                    <ItemWrapper width="30%">
                      <Typography color={color.grey[700]} fontSize={14}>
                        {service.legacyAcctNumber}
                      </Typography>
                    </ItemWrapper>
                  </TableItem>
                </TableContent>
              </Table>
            )}
        </Box>
      )) ?? null}
    </div>
  )
}

interface ILinkedAccountsTableMobileView {
  accountId: string
  serviceAddress: string
}

const LinkedAccountsTableMobileView = (
  props: ILinkedAccountsTableMobileView
): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      p={2}
      gap={2}
      borderBottom={`solid 1px ${color.background[200]}`}
    >
      <Box display="flex" flexDirection="column">
        <FormLabel textcolor={color.grey[600]}>Account Number</FormLabel>
        <Typography color={color.grey[700]} fontSize={14}>
          {props?.accountId}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column">
        <FormLabel textcolor={color.grey[600]}>Service Address</FormLabel>
        <Typography color={color.grey[700]} fontSize={14}>
          {props?.serviceAddress}
        </Typography>
      </Box>
    </Box>
  )
}

const Table = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  background: color.white
})

const TableHeader = styled(Box)({
  display: 'flex',
  borderBottom: color.background[200],
  fontSize: 14,
  fontWeight: 600,
  paddingLeft: 20,
  paddingRight: 20
})

const TableContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 20,
  paddingRight: 20
})

const TableItem = styled(Box)({
  display: 'flex'
  // '&:hover': {
  //   backgroundColor: color.primary[50]
  // }
})

const ItemWrapper = styled(Box)<{ width?: string }>(({ width }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  width,
  paddingTop: 10,
  paddingBottom: 10,
  color: color.black,
  fontSize: 14,
  fontWeight: 400
}))
