import CloseIcon from '@mui/icons-material/Close'
import LogoutIcon from '@mui/icons-material/Logout'
import { Box, Chip, IconButton, ListItem, ListItemButton, SwipeableDrawer, Typography, css, styled } from '@mui/material'
import Logo from 'assets/icons/ClientLogo.svg'
import LogoWhite from 'assets/icons/ClientLogoWhite.svg'
import MoleculeBottomCorner from 'assets/icons/MoleculeBottomCorner.svg'
import MoleculeTopCorner from 'assets/icons/MoleculeTopCorner.svg'
import { ReactComponent as UsageIcon } from 'assets/icons/UsageIcon.svg'
import { MButton } from 'components/ui'
import { EFFICIENCY_LINK, ROUTES_PATH, SIDEBAR_ITEMS } from 'config'
import { color } from 'config/color'
import { useAuth, useSidebar } from 'context'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { ServiceAddress } from './ServiceAddress'
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'

export const SideBar: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { openSidebar, setOpenSidebar } = useSidebar()
  const { handleLogOut, isGuestAccount, account, isAccountDisabled, isLoggedInAsGuest } = useAuth()
  const isMobile = useMobileBreakpoints()
  const { enqueueSnackbar } = useSnackbar()

  const handleLogout = async (): Promise<void> => {
    await handleLogOut()
    navigate(ROUTES_PATH.SIGNIN)
  }

  const handleListItemClick = (href: string): void => {
    if (href === EFFICIENCY_LINK) {
      window.open(href, '_blank')
    } else {
      navigate(href)
      setOpenSidebar(false)
    }
  }

  useEffect(() => {
    if (isAccountDisabled && typeof account?.errorMessage === 'string') {
      enqueueSnackbar(account?.errorMessage, {
        variant: 'error',
        autoHideDuration: 4000
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountDisabled, account])


  return (
    <>
      {isMobile
        ? <SwipeableDrawer PaperProps={{ sx: { borderTopLeftRadius: 10, borderBottomLeftRadius: 10 } }} anchor="right" open={openSidebar} onClose={() => { setOpenSidebar(false) }} onOpen={() => { setOpenSidebar(true) }}>
          <Box p={3} width="70vw" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography color={color.black} fontWeight={600} fontSize={24} >More Options</Typography>
                <IconButton onClick={() => { setOpenSidebar(false) }}><CloseIcon color='primary' /></IconButton>
              </Box>
              {SIDEBAR_ITEMS.filter(item => !isGuestAccount || item.title !== 'Account Preferences').map((item, index) => (
                <ListItem key={index} sx={{ my: 1.5 }} disablePadding style={{ opacity: isAccountDisabled ? 0.5 : 'unset', pointerEvents: isAccountDisabled ? 'none' : 'unset' }}>
                  <ListItemButton disableGutters onClick={() => { handleListItemClick(item.href) }}>
                    {(item.icon != null) ? <item.icon sx={{ color: item.href === location.pathname ? color.primary[600] : color.primary[700] }} /> : <UsageIcon fill={item.href === location.pathname ? color.primary[600] : color.primary[700]} />}
                    <Typography ml={2} fontSize={16} color={color.grey[900]} >{item.title}</Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </Box>
            <Box py={3} borderTop={`solid 1px ${color.white}`} display="flex" justifyContent="space-between" alignItems="center">
              <ListItemButton disableGutters onClick={handleLogout}>
                <LogoutIcon sx={{ color: color.primary[700], mr: 2 }} />
                <Typography fontSize={16} color={color.grey[900]} >Log out</Typography>
              </ListItemButton>
              <Box component="img" src={Logo} height={30} alt="background" />
            </Box>
          </Box>
        </SwipeableDrawer >
        : <Wrapper>
          <SidebarInner>
            <Box display="block" textAlign="center" my={2}>
              <img src={LogoWhite} height={50} alt="MVU Logo" />
            </Box>

            {(!isGuestAccount || isLoggedInAsGuest) && (
              <Box
                my={5}
                mx={2}
                display="flex"
                alignItems="center"
                flexDirection="column">
                <Box bgcolor={color.primary[800]} width="93%" display="flex" p={1} alignItems="center" justifyContent="center">
                  <Typography fontSize="12px" color={color.white} letterSpacing={1}>
                    SERVICE ADDRESS
                  </Typography>
                </Box>
                <ServiceAddress />
              </Box>
            )}

            {(isGuestAccount && !isLoggedInAsGuest) && (
              <Box display="flex" my={0} m={0} alignItems="center" justifyContent="center" flexDirection="column">
                <Typography fontSize="12px" color={color.primary[50]} letterSpacing={1} m={1}>
                  Guest Account
                </Typography>
                <Chip label="Back to main account" color="primary" onClick={() => { window.location.reload() }} />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="calc(100vh - 300px)"
            >
              <SideNav style={{ opacity: isAccountDisabled ? 0.5 : 'unset', pointerEvents: isAccountDisabled ? 'none' : 'unset' }}>
                {SIDEBAR_ITEMS.filter(item => (isGuestAccount || isLoggedInAsGuest) ? item.title !== 'Account Preferences' : item).map((link) => (
                  <li key={link.title}>
                    <NavItem
                      to={link.href}
                      {...(link.href === EFFICIENCY_LINK ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
                      isactive={link.href === location.pathname ? 'true' : 'false'}
                    >
                      {(link.icon != null) ? <link.icon sx={{ color: link.href === location.pathname ? color.background[900] : color.background[600] }} /> : <UsageIcon fill={link.href === location.pathname ? color.background[900] : color.background[600]} />}
                      <span>{link.title}</span>
                    </NavItem>
                  </li>
                ))}
              </SideNav>
              <SideFooter>
                <li>
                  <MButton
                    fullWidth
                    variant="outlined"
                    type="button"
                    rounded="true"
                    onClick={handleLogout}
                    sx={{ fontSize: 14, color: color.white, borderColor: color.background[600] }}
                    texttransform="none"
                    startIcon={<LogoutIcon />}
                  >
                    Log out
                  </MButton>
                </li>
              </SideFooter>
            </Box>
          </SidebarInner>
        </Wrapper >
      }
    </>
  )
}

const Wrapper = styled(Box)({
  backgroundColor: color.background[900],
  height: 'auto',
  left: '0',
  top: '0',
  paddingBottom: '100%',
  position: 'absolute',
  width: '240px',
  zIndex: '999',
  transition: 'all 0.25s'

})
const SidebarInner = styled(Box)`
  padding-top: 56px;
  overflow: hidden;
    height: auto;
`

const SideFooter = styled('ul')({
  paddingLeft: '48px',
  paddingRight: '30px',
  listStyleType: 'none',

  li: {
    marginBottom: '10px',

    a: {
      color: color.white,
      cursor: 'pointer',
      display: 'block',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '17px',
      padding: '0',
      margin: '0',
      textDecoration: 'none'
    },

    '&:last-child': {
      marginBottom: '0'
    }
  }
})

const SideNav = styled('ul')({
  margin: '39px 0 0 20px',
  padding: 0,

  li: {
    display: 'block',
    marginBottom: '9px'
  }
})


const NavItem = styled(Link) <{ isactive: string }>`
  color: ${color.white};
  display: flex;
  align-items: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 12px 39px 11px 18px;
  position: relative;
  text-decoration: none;
  cursor: pointer;

  ${(props) => css`
    ${props.isactive === 'true' &&
    css`
      background-color: ${color.background[50]} !important;
      color: ${color.grey[900]};

      &:before {
        content: url(${MoleculeTopCorner});
        position: absolute;
        right: 0;
        top: -40px;
        width: 40px;
        height: 40px;
      }

      &:after {
        content: url(${MoleculeBottomCorner});
        position: absolute;
        right: 0;
        bottom: -40px;
        width: 40px;
        height: 40px;
      }
    `}
  `}

  span {
    font-size: 14px;
    line-height: 17px;
    padding: 0 0 0 8px;
    position: relative;
  }

  img: {
    position: absolute;
    height: 20px;
    width: 20px;
  }
`
