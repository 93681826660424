import { type AxiosInstance, type AxiosRequestConfig } from 'axios'
import { ENDPOINTS } from 'config'

import { type AccountResponse, type BillingResponse, type DepositResponse, type ICAuthResponse, type MeterReadHistory, type PaymentResponse, type UsageResponse } from 'utils/hooks/types'
import {
  type IAddGuest,
  type IAdmin,
  type IBilling,
  type ICloudPay,
  type IConfirmRegister,
  type IContact,
  type IICAuth,
  type IMeterHistory,
  type IPayments,
  type IRegister,
  type ISearchLocation,
  type IServiceRequest,
  type IUpdateMailAddress,
  type IUpdateUser,
  type IUsageV2,
  type IUser,
  type IVerifyPayBill,
  type ILinkGuest2,
  type IUnlinkGuest2
} from './apiTypes'
import { axios } from './axiosInstance'

class RestAPIs {
  private readonly requestInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.requestInstance = axiosInstance
  }

  private getHeader(token: string): AxiosRequestConfig {
    return {
      headers: { Authorization: `Bearer ${token}` }
    }
  }

  getCloudData = async (param: ICloudPay): Promise<any> =>
    await this.requestInstance.post(ENDPOINTS.CLOUDPAY, param)

  updateUser = async (param: IUpdateUser): Promise<any> =>
    await this.requestInstance.post(
      (param.admin === true) ? ENDPOINTS.UPDATE_AUTH_ADMIN : ENDPOINTS.UPDATE,
      param,
      this.getHeader(param.AccessToken)
    )

  registerUser = async (param: IRegister): Promise<any> =>
    await this.requestInstance.post(ENDPOINTS.REGISTER, param)

  verifyAccount = async (param: any): Promise<any> =>
    await this.requestInstance.post(
      ENDPOINTS.VERIFY_ACCOUNT,
      param,
      this.getHeader(param.AccessToken)
    )

  checkEmail = async (param: any): Promise<any> =>
    await this.requestInstance.post(
      ENDPOINTS.CHECK_EMAIL,
      param
    )

  getEmailAddress = async (param: IUser): Promise<any> =>
    await this.requestInstance.post(
      ENDPOINTS.ACCOUNT_EMAIL,
      param,
      this.getHeader(param.AccessToken)
    )


  contactUs = async (param: IContact): Promise<any> =>
    await this.requestInstance.post(
      (param.AccessToken.length > 0) ? ENDPOINTS.CONTACT_US : ENDPOINTS.CONTACT_US_PUBLIC,
      param,
      this.getHeader(param.AccessToken)
    )


  getAdmin = async (param: IAdmin): Promise<any> =>
    await this.requestInstance.post(ENDPOINTS.ADMIN, param)

  getAccount = async (param: IUser): Promise<AccountResponse> =>
    await this.requestInstance.post(
      (param.admin === true) ? ENDPOINTS.ACCOUNT_AUTH_ADMIN : ENDPOINTS.ACCOUNT,
      param,
      this.getHeader(param.AccessToken)
    )

  getICAuth = async (param: IICAuth): Promise<ICAuthResponse> =>
    await this.requestInstance.post(
      (param.admin === true) ? ENDPOINTS.IC_AUTH_ADMIN : ENDPOINTS.IC_AUTH,
      param,
      this.getHeader(param.AccessToken)
    )

  getBilling = async (param: IBilling): Promise<BillingResponse> =>
    await this.requestInstance.post(
      (param.admin === true) ? ENDPOINTS.BILLING_AUTH_ADMIN : ENDPOINTS.BILLING,
      param,
      this.getHeader(param.AccessToken)
    )

  getDeposits = async (param: IBilling): Promise<DepositResponse> =>
    await this.requestInstance.post(
      (param.admin === true) ? ENDPOINTS.DEPOSIT_ADMIN : ENDPOINTS.DEPOSIT,
      param,
      this.getHeader(param.AccessToken)
    )

  getPayments = async (param: IPayments): Promise<PaymentResponse> =>
    await this.requestInstance.post(
      (param.admin === true) ? ENDPOINTS.PAYMENT_AUTH_ADMIN : ENDPOINTS.PAYMENT,
      param,
      this.getHeader(param.AccessToken)
    )

  getUsage = async (param: IUsageV2): Promise<UsageResponse> =>
    await this.requestInstance.post(
      (param.admin === true) ? ENDPOINTS.USAGE_V2_AUTH_ADMIN : ENDPOINTS.USAGE_V2,
      param,
      this.getHeader(param.AccessToken)
    )

  addGuest = async (param: IAddGuest): Promise<any> =>
    await this.requestInstance.post(
      ENDPOINTS.ADD_GUEST_USER,
      param,
      this.getHeader(param.AccessToken)
    )

  linkGuest = async (param: ILinkGuest2): Promise<any> =>
    await this.requestInstance.post(
      ENDPOINTS.LINK_GUEST_USER,
      param,
      this.getHeader(param.AccessToken)
    )

  unlinkGuest = async (param: IUnlinkGuest2): Promise<any> =>
    await this.requestInstance.post(
      ENDPOINTS.UNLINK_GUEST_USER,
      param,
      this.getHeader(param.AccessToken)
    )

  updateMailAddress = async (param: IUpdateMailAddress): Promise<any> =>
    await this.requestInstance.put(
      ((param.admin === true))
        ? ENDPOINTS.UPDATE_MAIL_ADDRESS_AUTH_ADMIN
        : ENDPOINTS.UPDATE_MAIL_ADDRESS,
      param,
      this.getHeader(param.AccessToken)
    )

  getOutages = async (): Promise<any> =>
    await this.requestInstance.get(ENDPOINTS.OUTAGES)

  verifyPayBill = async (param: IVerifyPayBill): Promise<any> =>
    await this.requestInstance.post(ENDPOINTS.VERIFY_PAY_BILL, param)

  confirmRegister = async (param: IConfirmRegister): Promise<any> =>
    await this.requestInstance.post(ENDPOINTS.CONFIRM_REGISTER, param)

  searchLocation = async (param: ISearchLocation): Promise<any> => await this.requestInstance.post(ENDPOINTS.SEARCH_LOCATION, param)

  sendServiceRequest = async (param: IServiceRequest): Promise<any> => await this.requestInstance.post(ENDPOINTS.SERVICE_REQUEST, param)

  getMeterReadHistory = async (param: IMeterHistory): Promise<MeterReadHistory> =>
    await this.requestInstance.post(
      ENDPOINTS.METER_HISTORY,
      param,
      this.getHeader(param.AccessToken)
    )
}

export const APIs = new RestAPIs(axios)
