import {
  Box,
  Typography,
  Divider,
  IconButton,
  Dialog,
  Radio
} from '@mui/material'
import { Close, Link, LinkOffOutlined } from '@mui/icons-material'
import { MButton } from 'components'
import { color } from 'config/color'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { useAuth } from 'context'
import { type ISecondaryPerList } from 'utils/hooks/types'
import { type ILinkGuest } from 'services'

interface ISelectAccountsDialog {
  open: boolean
  onClose: () => void
  selectedServiceAccount: {
    accountNumber: string
    serviceAddress: string
  } | null
  linkGuestAccount: (data: ILinkGuest) => void
  isLoading: boolean
  setGuestUser: Dispatch<SetStateAction<{ personName: string, personId: string, accountId: string, phoneNumber: string, email: string, guestAcctId: string }>>
  guestUsers: {
    personName: string
    personId: string
    accountId: string
    email: string
    phoneNumber: string
    guestAcctId: string
  }
}

export const SelectAccountsDialog = ({
  open,
  selectedServiceAccount,
  onClose,
  linkGuestAccount,
  setGuestUser,
  isLoading,
  guestUsers
}: ISelectAccountsDialog): JSX.Element => {
  const { account } = useAuth()
  const [selectedAccount, setSelectedAccount] = useState<ISecondaryPerList | null>(null)
  const secondaryPerList = account?.secondaryPerList ?? []

  useEffect(() => {
    setSelectedAccount(null)
  }, [open])

  const handleOnChange = (guest: ISecondaryPerList): void => {
    setGuestUser({
      personName: guest.personName ?? null,
      personId: guest?.personId ?? null,
      guestAcctId: guest?.guestAcctId ?? null,
      accountId: selectedServiceAccount?.accountNumber ?? '',
      phoneNumber: guest?.phoneNumber ?? null,
      email: guest?.email ?? null
    })
    setSelectedAccount(guest)
  }

  const checkIfLinked = (secondaryPerson: ISecondaryPerList, accountId: string): boolean => {
    return secondaryPerson.linkedTo?.some((i) => i.acctList === accountId) && secondaryPerson.isRegisteredToPortal
  }

  return (
    <Box>
      <Dialog fullWidth open={open} onClose={onClose}>
        <Box>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <Close color="primary" />
            </IconButton>
          </Box>
        </Box>

        <Box>
          <Box display="flex" flexDirection="column" gap={1} px={6} pb={0}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" flexDirection="column">
                <Typography
                  color={color.grey[900]}
                  fontWeight={500}
                  fontSize={24}
                >
                  Link User
                </Typography>

                <Typography
                  color={color.grey[700]}
                  fontWeight={400}
                  fontSize={16}
                >
                  Please select the user(s) you would like to link to the following account:
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap={1} p={1}>
                <Typography
                  color={color.grey[700]}
                  fontWeight={400}
                  fontSize={12}
                  textTransform="uppercase"
                >
                  Account number
                </Typography>
                <Typography
                  color={color.grey[900]}
                  fontWeight={400}
                  fontSize={14}
                  textTransform="uppercase"
                >
                  {selectedServiceAccount?.accountNumber}
                </Typography>

                <Typography
                  color={color.grey[700]}
                  fontWeight={400}
                  fontSize={12}
                  textTransform="uppercase"
                >
                  Service Address
                </Typography>

                <Typography
                  color={color.grey[900]}
                  fontWeight={400}
                  fontSize={14}
                >
                  {selectedServiceAccount?.serviceAddress}
                </Typography>
              </Box>
            </Box>
          </Box>


          <Box display="flex" flexDirection="column" gap={1} px={4} py={0}>
            <Divider sx={{ color: color.background[300] }} />

            <Box px={4}>
              <Typography
                color={color.grey[600]}
                fontSize={12}
                fontWeight={400}
                textTransform="uppercase"
              >
                Available Users
              </Typography>
            </Box>

            {secondaryPerList.map((guest) => {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  key={guest.personId}
                  bgcolor={color.white}
                  px={3}
                  py={1}
                  gap={1}
                >
                  <Box display="flex" flexDirection="row" border={`1px solid ${color.grey[200]}`} alignItems="center" p={0}>
                    <Radio
                      disabled={checkIfLinked(guest, selectedServiceAccount?.accountNumber ?? '')}
                      name="selected"
                      checked={guest.guestAcctId === selectedAccount?.guestAcctId}
                      onChange={() => {
                        handleOnChange(guest)
                      }}
                    />

                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      color={color.grey[900]}
                      sx={{
                        opacity: checkIfLinked(guest, selectedServiceAccount?.accountNumber ?? '') ? 0.5 : 1
                      }}
                      width="100%"
                    >
                      {guest.personName}
                    </Typography>

                    <Box display="flex" flexDirection="row" justifyContent="end" p={0.5} width="100%">
                      <Box
                        sx={{
                          opacity: checkIfLinked(guest, selectedServiceAccount?.accountNumber ?? '') ? 0.5 : 1
                        }}
                        display="flex"
                        justifyItems="end"
                        flexDirection="row"
                        alignItems="center"
                        border={`0.5px solid ${color.grey[500]}`}
                        borderRadius={40}
                        gap={1}
                        p={0.5}
                      >

                        {checkIfLinked(guest, selectedServiceAccount?.accountNumber ?? '')
                          ? (
                            <Link
                              sx={{
                                color: color.white,
                                backgroundColor: color.success[500],
                                borderRadius: '50%',
                                fontSize: 16,
                                padding: 0.5
                              }}
                            />
                          )
                          : (
                            <LinkOffOutlined
                              sx={{
                                color: color.white,
                                backgroundColor: color.warning[500],
                                borderRadius: '50%',
                                fontSize: 16,
                                padding: 0.5
                              }}
                            />
                          )}

                        <Typography
                          color={color.grey[700]}
                          fontWeight={400}
                          fontSize={10}
                          mr={1}
                        >
                          {checkIfLinked(guest, selectedServiceAccount?.accountNumber ?? '') ? 'Linked' : 'Not Linked'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            })}

            <Box display="flex" flexDirection="column" px={3} py={1} gap={1}>
              <MButton
                loading={isLoading}
                type="button"
                variant="contained"
                rounded="true"
                texttransform="none"
                onClick={(() => {
                  const body = {
                    personId: guestUsers.personId,
                    accountList: [{
                      accountId: guestUsers.accountId
                    }]
                  }
                  linkGuestAccount(body)
                })}
                disabled={selectedAccount === null || isLoading}
              >
                Confirm Options
              </MButton>

              <MButton
                variant="text"
                type="button"
                rounded="true"
                texttransform="none"
                onClick={onClose}
              >
                Go back to my Account
              </MButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}
