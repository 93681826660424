import { Box, Typography } from '@mui/material'
import { color } from 'config/color'
import { useLocalStorage, useMobileBreakpoints } from 'utils'
import { AddUserBox } from './AddUserBox'
import { GuestUserCard } from './GuestUserCard'
import { AccountTable } from './AccountTable'
import { NewUserDialog } from './NewUserDialog'
import { useEffect, useState } from 'react'
import { RemoveUserDialog } from './RemoveUserDialog'
import { useAuth } from 'context'
import { type ISecondaryPerList } from 'utils/hooks/types'
import { APIs, type IAddGuest, type ILinkGuest } from 'services'
import { USER_TOKEN } from 'config'
import { ResponseDialog } from './ResponseDialog'
import { SelectAccountsDialog } from './SelectAccountsDialog'

interface IFormData {
  personName: string
  phoneNumber: string
  email: string
  emailConfirm: string
  isLoading: boolean
}

export const SecondaryUser = ({ secondaryPerList }: { secondaryPerList: ISecondaryPerList[] }): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  const { account, currentAccount, getAccount } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const { serviceAddresses, accountId } = account?.myAccount ?? {}
  const [isNewUserDialogOpen, setIsNewUserDialogOpen] =
    useState<boolean>(false)
  const [isResponseDialogOpen, setIsResponseDialogOpen] =
    useState<boolean>(false)
  const [isLinkDialogOpen, setIsLinkDialogOpen] =
    useState<boolean>(false)
  const [isRemoveUserDialogOpen, setIsRemoveUserDialogOpen] =
    useState<boolean>(false)
  const [selectedParentAccount, setSelectedParentAccount] =
    useState<string | null>(null)
  const [selectedServiceAccount, setSelectedServiceAccount] =
    useState<{
      accountNumber: string
      serviceAddress: string
    }>({
      accountNumber: '',
      serviceAddress: ''
    })
  const [responseType, setResponseType] =
    useState<'success' | 'error' | 'warning' | null>(null)
  const [dialogType, setDialogType] =
    useState<'add' | 'link' | 'unlink' | 'addedAndLinked' | null>(null)
  const [personChar, setPersonChar] = useState<boolean>(false)
  const [didChange, setDidChange] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [guestUser, setGuestUser] =
    useState<{
      personName: string
      personId: string
      accountId: string
      email: string
      phoneNumber: string
      guestAcctId: string
    }>({
      personName: '',
      personId: '',
      accountId: '',
      email: '',
      phoneNumber: '',
      guestAcctId: ''
    })

  useEffect(() => {
    if (currentAccount?.accountId === account?.myAccount?.accountId && !didChange) {
      return
    }
    if (didChange) {
      void getAccount({
        AccessToken: authToken,
        accountId
      })
    }
    setDidChange(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didChange])


  const addUserSubmit = async (data: IFormData): Promise<void> => {
    if ((account?.myAccount.accountId) == null) return
    setIsLoading(true)
    try {
      const body: IAddGuest = {
        AccessToken: authToken,
        parentAccount: selectedParentAccount ?? account?.myAccount?.accountId,
        personName: data.personName,
        emailAddress: data.email,
        phoneNumber: data.phoneNumber
      }

      const response = await APIs.addGuest(body)

      if (response?.alreadyLink === false) {
        const linkBody: ILinkGuest = {
          personId: response.personId,
          accountList: [{
            accountId: accountId ?? ''
          }]
        }
        await linkGuestAccount(linkBody)
      }

      if (response?.alreadyLink === true) {
        setResponseType('success')
        setDialogType('addedAndLinked')
        setIsResponseDialogOpen(true)
      }

      if (response.personId !== null && response.alreadyLink === undefined) {
        setPersonChar(response.personCharValue ?? false)
        setResponseType('success')
        setDialogType('add')
        setIsResponseDialogOpen(true)
      }
    } catch (e: any) {
      const { data } = e.response
      console.error('SignUp.Form.GUEST', data)
    }

    setSelectedParentAccount(null)
    setIsLoading(false)
    setIsNewUserDialogOpen(false)
  }

  const linkGuestAccount = async (body: any): Promise<void> => {
    try {
      if (accountId === undefined || guestUser.personId === null) return
      setIsLoading(true)

      body.AccessToken = authToken
      const response = await APIs.linkGuest(body)

      if (response?.linked === true) {
        setResponseType('success')
        setDialogType('link')
        setIsResponseDialogOpen(true)
      }

      setIsLinkDialogOpen(false)
    } catch (error) {
      console.error(error)
      setResponseType('error')
    }
    setIsLoading(false)
  }

  const unlinkPerson = async (body: any): Promise<void> => {
    try {
      if (accountId === undefined || guestUser.personId === '') throw new Error('Account Id or Person ID is required')
      setIsLoading(true)
      body.AccessToken = authToken

      const response = await APIs.unlinkGuest(body)

      if (response?.success === 'true') {
        setResponseType('success')
        setDialogType('unlink')
        setIsResponseDialogOpen(true)
      }

      setIsRemoveUserDialogOpen(false)
    } catch (error) {
      console.error(error)
      setResponseType('error')
    }
    setIsLoading(false)
    setSelectedServiceAccount({
      accountNumber: '',
      serviceAddress: ''
    })
  }

  return (
    <Box
      bgcolor={color.white}
      p={3}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={2}
      sx={{ opacity: 0.5, pointerEvents: 'none' }}
    >
      <Box
        bgcolor={color.grey[600]}
        display="flex"
        justifyContent="center"
      >
        <Typography color={color.black}>This feature is not yet available</Typography>
      </Box>

      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="flex-start"
        justifyContent="space-between"
        gap={1}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography
            color={color.grey[900]}
            fontSize={isMobile ? 18 : 24}
            fontWeight={600}
          >
            Guest Users
          </Typography>

          <Typography color={color.grey[800]} fontSize={16} fontWeight={400}>
            Enable up to 2 users to view into the same account, simplifying collaboration. Start sharing now!
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        gap={2}
        sx={{ opacity: 0.5 }}
      >
        {secondaryPerList.length > 0 && secondaryPerList.map(secondaryPerson => {
          return (
            <GuestUserCard
              key={secondaryPerson.personId}
              secondaryPerson={secondaryPerson}
              setIsRemoveUserDialogOpen={setIsRemoveUserDialogOpen}
              setGuestUser={setGuestUser}
            />
          )
        })}

        {secondaryPerList.length === 0 && ([1, 2].map((i) => (
          <AddUserBox key={i} setIsNewUserDialogOpen={setIsNewUserDialogOpen} />
        )))}

        {secondaryPerList.length === 1 && <AddUserBox setIsNewUserDialogOpen={setIsNewUserDialogOpen} />}
      </Box>

      <AccountTable
        serviceAddresses={serviceAddresses ?? []}
        setIsNewUserDialogOpen={setIsNewUserDialogOpen}
        setSelectedParentAccount={setSelectedParentAccount}
        setIsLinkDialogOpen={setIsLinkDialogOpen}
        setSelectedServiceAccount={setSelectedServiceAccount}
        setIsRemoveUserDialogOpen={setIsRemoveUserDialogOpen}
        setGuestUser={setGuestUser}
      />

      <NewUserDialog
        isLoading={isLoading}
        open={isNewUserDialogOpen}
        addUserSubmit={addUserSubmit}
        onClose={() => {
          setIsNewUserDialogOpen(false)
        }}
      />

      <ResponseDialog
        type={responseType}
        dialogType={dialogType}
        personName={guestUser.personName}
        personChar={personChar}
        accountId={accountId ?? ''}
        setDidChange={setDidChange}
        open={isResponseDialogOpen}
        onClose={() => {
          setIsResponseDialogOpen(false)
        }}
      />

      <SelectAccountsDialog
        selectedServiceAccount={selectedServiceAccount}
        open={isLinkDialogOpen}
        onClose={() => {
          setIsLinkDialogOpen(false)
          setSelectedServiceAccount({
            accountNumber: '',
            serviceAddress: ''
          })
        }}
        linkGuestAccount={linkGuestAccount}
        guestUsers={guestUser}
        isLoading={isLoading}
        setGuestUser={setGuestUser}
      />

      <RemoveUserDialog
        isLoading={isLoading}
        open={isRemoveUserDialogOpen}
        service={selectedServiceAccount}
        isUnlink={selectedServiceAccount.accountNumber !== ''}
        onClose={() => {
          setIsRemoveUserDialogOpen(false)
          setSelectedServiceAccount({
            accountNumber: '',
            serviceAddress: ''
          })
        }}
        guestUser={guestUser}
        unlinkPerson={unlinkPerson}
      />
    </Box>
  )
}
