import InsertPageBreakOutlinedIcon from '@mui/icons-material/InsertPageBreakOutlined'
import { Avatar, Box, Typography } from '@mui/material'
import { MESSAGES, PAPERLESSFLG, USER_TOKEN } from 'config'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { APIs } from 'services'
import { color } from 'config/color'
import { useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { type AccountResponse } from 'utils/hooks/types'
import { YesNoRadio } from 'components/ui/RadioGroup/YesNoRadioPaperless'
import { PaperlessBillingDialog } from './PaperlessBillingDialog'
import { useAuth } from 'context'

interface IPaperlessBilling {
    account: AccountResponse | null
    isAdmin: boolean
    userEmail: string
}

export const PaperlessBilling = ({
    account,
    isAdmin,
    userEmail
}: IPaperlessBilling): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar()
    const { isGuestAccount, isLoggedInAsGuest } = useAuth()
    const [authToken] = useLocalStorage(USER_TOKEN, '')
    const [paperlessBilling, setPaperlessBilling] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const isMobile = useMobileBreakpoints()

    useEffect(() => {
        if (account?.myAccount != null) {
            setPaperlessBilling(account.myAccount.paperlessFlg === 'Y')
        }
    }, [account])

    const handleUpdateSettings = async (): Promise<void> => {
        setIsLoading(true)
        const body: any = {
            AccessToken: authToken,
            accountId: account?.myAccount.accountId,
            admin: isAdmin,
            email: userEmail,
            updates: [
                {
                    object: PAPERLESSFLG,
                    value: paperlessBilling ? 'Y' : 'N'
                }
            ]
        }

        try {
            const response = await APIs.updateUser(body)
            if (response?.status === 1) {
                enqueueSnackbar(MESSAGES.PREFERENCES_UPDATED_SUCCESS, {
                    variant: 'success',
                    autoHideDuration: 3000
                })
            }
        } catch (e: any) {
            if (e.response?.status === 400) {
                enqueueSnackbar(
                    `${e.response.data.message}. ${MESSAGES.CONTACT_CUSTOMER_ERROR}`,
                    {
                        variant: 'error',
                        autoHideDuration: 5000
                    }
                )
            }
            setPaperlessBilling(!paperlessBilling)
        }
        setIsLoading(false)
        setOpen(false)
    }

    const handlePaperlessBillingChange = (event: any): void => {
        setPaperlessBilling(event.target.value === 'Y')
        setOpen(true)
    }

    const handleClose = (): void => {
        setPaperlessBilling(!paperlessBilling)
        setOpen(false)
    }

    return (
        <Box
            flex={1}
            p={3}
            borderRadius='4px'
            justifyContent='space-between'
            alignItems='center'
            border='1px solid #E5E5F6'
        >
            <Box alignItems='center'>
                <Box
                    display='flex'
                    flexDirection='column'
                    borderBottom='1px solid #E5E5F6'
                    gap={2}
                >
                    <Box
                        display='flex'
                        alignItems='center'
                        flexDirection={isMobile ? 'row' : 'column'}
                        gap={2}
                    >
                        <Avatar
                            sx={{
                                bgcolor: `${color.grey[100]}`,
                                width: isMobile ? 50 : 80,
                                height: isMobile ? 50 : 80
                            }}
                        >
                            <InsertPageBreakOutlinedIcon
                                fontSize={isMobile ? 'small' : 'large'}
                                sx={{ color: `${color.primary[600]}` }}
                            />
                        </Avatar>
                        <Typography
                            color={color.grey[900]}
                            fontSize={isMobile ? 18 : 24}
                            fontWeight={600}
                            textAlign='center'
                        >
                            Paperless Billing
                        </Typography>
                    </Box>
                    <Typography
                        color={color.grey[700]}
                        fontSize={isMobile ? 14 : 16}
                        textAlign={isMobile ? 'justify' : 'center'}
                        mb={3}
                    >
                        When you select paperless billing, you will no longer receive a
                        paper bill in the mail, and instead your bill will be emailed to
                        you.
                    </Typography>
                </Box>
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    my={2}
                    sx={{ pointerEvents: (isGuestAccount || isLoggedInAsGuest) ? 'none' : 'auto' }}
                >
                    <Typography
                        color={color.grey[900]}
                        fontSize={isMobile ? 14 : 16}
                        textAlign={isMobile ? 'justify' : 'center'}
                    >
                        Do you want to enroll in paperless billing?
                    </Typography>
                    <YesNoRadio
                        value={paperlessBilling}
                        onChange={handlePaperlessBillingChange}
                    />
                </Box>
                <PaperlessBillingDialog
                    open={open}
                    onClose={handleClose}
                    onConfirm={handleUpdateSettings}
                    isLoading={isLoading}
                    paperlessBilling={paperlessBilling}
                />
            </Box>
        </Box>
    )
}
