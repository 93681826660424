import {
  Box
} from '@mui/material'
import { ProgressLoaders } from 'components'
import {
  USER_TOKEN
} from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { ChangePassword } from './components/ChangePassword'
import { NotificationPreferences } from './components/NotificationPreferences'
import { PaperlessBilling } from './components/PaperlessBilling'
import UpdateEmail from './components/UpdateEmail'

export const AccountPreferences: React.FC = () => {
  const { account, currentAccount, getAccount, isAdmin, userEmail, isGuestAccount, isLoggedInAsGuest } =
    useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const isMobile = useMobileBreakpoints()
  const [isPrefUpdated, setIsPrefUpdated] = useState<boolean>(false)
  const [prevAccountId, setPrevAccountId] = useState<string>('')

  useEffect(() => {
    return () => {
      if (isPrefUpdated) {
        void getAccount({
          AccessToken: authToken,
          accountId: currentAccount?.accountId ?? '',
          admin: isAdmin,
          email: userEmail
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrefUpdated])

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  return (
    <>

      {account !== null
        ? <Box>
          <Box mt={3} p={3} bgcolor={color.white} borderRadius="4px">
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={3}>
              <Box flex={1} display="flex" flexDirection="column" gap={3}>
                <PaperlessBilling account={account} isAdmin={isAdmin} userEmail={userEmail} />
                <UpdateEmail />
              </Box>
              <ChangePassword />
            </Box >
          </Box>

          {(!isGuestAccount || isLoggedInAsGuest) && (
            <Box display="flex" p={2} justifyContent="space-between" alignItems="center" bgcolor={color.white} borderRadius="4px" marginTop="20px">
              <NotificationPreferences setIsPrefUpdated={setIsPrefUpdated} />
            </Box >
          )}

        </Box>
        : <Box mt={3}><ProgressLoaders height='100%' /></Box>}
    </>

  )
}
