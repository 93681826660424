import { Box, Typography } from '@mui/material'
import { MButton, SvgImage, Text } from 'components'
import { ROUTES_PATH } from 'config'
import { color } from 'config/color'
import type React from 'react'
import { useNavigate } from 'react-router-dom'

interface NoDataProps {
  transactionType: string
}
export const NoData: React.FC<NoDataProps> = ({ transactionType }) => {
  const navigate = useNavigate()
  return (
    <Box
      marginTop="60px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <SvgImage name="NoDataIcon" width={100} height={100} />
      <Text padding="20px 0" fontSize="24px" color={color.black} fontWeight="600">
        There is no data to be displayed.
      </Text>
      {transactionType === 'deposit'
        ? (
          <Typography style={{ margin: '0' }} color={color.grey[700]} paragraph>
            Please refer to your water bill to view your actual deposit balance. New deposits made will be reflected on this page in the future.
          </Typography>
        )
        : (
          <>
            <Typography style={{ margin: '0' }} color={color.grey[700]} paragraph>
              {`It looks like you haven't completed any ${transactionType} transactions so far. If you think`}
            </Typography>
            <Typography style={{ marginBottom: '20px' }} color={color.grey[700]}>
              this is a mistake, please contact us.
            </Typography>
            <MButton
              variant="contained"
              texttransform="none"
              rounded="true"
              sx={{ fontSize: '16px', marginBottom: '20px' }}
              size="large"
              onClick={() => { navigate(ROUTES_PATH.CONTACT) }}
            >
              Contact Us
            </MButton>
          </>
        )}
    </Box>
  )
}
